import { Col, Layout, Row } from 'antd';
import { extractProjectId, ProjectsMenu } from './ProjectsMenu';
import React, { useEffect } from 'react';
import { useProjects } from '../hooks';
import { useLocalStorageState } from '../../../contexts/shared/hooks';
import { Comparator } from '../../../domain';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import styled from 'styled-components';
import { PageLoading } from '../../../contexts/shared/components/PageLoading';
import { useProjectTypeParam } from '../hooks/useProjectTypeParam';
import { useParams } from 'react-router-dom';

interface ProjectsLayoutProps {
  children: JSX.Element;
}

// This is the height of the full height sider minus the header
const siderHeight = 'calc(100vh - 64px)';
export const projectCardHeight = 'calc(100vh - 64px - 42px)';
export const projectCardWidth = 'calc(100vw - 254px)';

const FullHeightCol = styled(Col)`
  min-height: 100% !important;
`;

const StyledLayoutContent = styled(Layout.Content)`
  min-height: 100% !important;
`;

const ProjectsLayout: React.FC<ProjectsLayoutProps> = ({ children }) => {
  const projects = useProjects();
  const { projectId } = useParams();
  const [pinnedProjects] = useLocalStorageState<string[]>('pinned-projects');
  const [type] = useSearchParameter('type');

  const [active, setActive] = useSearchParameter('active');
  const typeFilteredProjects = (
    projects.data?.filter((p) => {
      return type ? p.projectType.idProjectType.toString() === type : true;
    }) || []
  )
    .sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
    .sort((a, b) => {
      const aN = pinnedProjects?.includes(a.idProject.toString()) ? 1 : 0;
      const bN = pinnedProjects?.includes(b.idProject.toString()) ? 1 : 0;
      return bN - aN;
    });

  const loading = projects.isLoading;

  // It's safer to use the param since it is always defined
  const activeProject =
    typeFilteredProjects?.find((p) => p.idProject.toString() === (projectId ?? active)) ??
    (typeFilteredProjects.length > 0 ? typeFilteredProjects.find((proj) => !proj.isArchived) : undefined);

  // Set project type param based on active project
  useProjectTypeParam(activeProject);

  useEffect(() => {
    if (!active && activeProject && String(activeProject?.projectType?.idProjectType) === type) {
      // This fallback is made for the default one to be set only when there is no inferred active project.
      const activeProjectId = projectId || extractProjectId(location.pathname) || activeProject.idProject.toString();
      setActive(activeProjectId);
    }
  }, [active, activeProject, setActive, type, projectId]);

  if (loading) return <PageLoading />;

  return (
    <Layout style={{ position: 'relative', marginTop: 0 }}>
      <Row wrap={false}>
        <Col>
          <Layout.Sider
            width={240}
            style={{
              position: 'sticky',
              marginRight: 8,
              overflowY: 'auto',
              overflowX: 'hidden',
              height: siderHeight
            }}
          >
            <ProjectsMenu active={active} setActive={setActive} selectableProjects={typeFilteredProjects} />
          </Layout.Sider>
        </Col>
        <FullHeightCol>
          <StyledLayoutContent>{children}</StyledLayoutContent>
        </FullHeightCol>
      </Row>
    </Layout>
  );
};

export default ProjectsLayout;
