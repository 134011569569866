import { Navigate } from 'react-router-dom';
import { useBundleConfigurations, useBundles } from '../../../contexts/bundles';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import { PageLoading } from '../../../contexts/shared/components/PageLoading';

const ProjectSoftwareRedirect: React.FC = () => {
  const [projectId] = useSearchParameter('projectId');
  const [bundleName] = useSearchParameter('bundleName');
  const [dialogType] = useSearchParameter('dialog');
  const [appId] = useSearchParameter('appId');
  const bundles = useBundles(projectId ?? '-1');

  // TODO: This has to be changed with project software
  const bundleLabel = bundleName === 'apps' ? 'Applications' : 'Tools';
  const usedBundle = bundles.data?.find((bundle) => bundle.name.includes(bundleLabel));
  const bundleId = usedBundle?.idBundle?.toString() || '-1';

  const configurations = useBundleConfigurations(projectId ?? '-1', bundleId);
  const firstConfig = configurations.data?.[0].idBundleConfiguration;
  const navigationLink = `/projects/${projectId}/${bundleName}/configurations/${firstConfig}?show=all&active=${projectId}&open_app_${dialogType}=${appId}`;

  if (configurations.isLoading) {
    return <PageLoading />;
  }

  if (!configurations.data || configurations.isError) {
    console.warn('configurations could not be retrieved', configurations);
    return <Navigate to="/404" replace />;
  }

  if (!appId || !bundleName || !projectId || !configurations.data?.length) {
    // if no configuration are found found navigate to home
    // with open notifications panel
    return <Navigate to="/?showNotifications=true" replace />;
  }

  return <Navigate to={navigationLink} replace />;
};

export default ProjectSoftwareRedirect;
