import React from 'react';
import { SystemAnnouncement } from '../components/renderers/SystemAnnouncement';
import { SystemTraining } from '../components/renderers/SystemTraining';
import { SystemWarning } from '../components/renderers/SystemWarning';
import { PacTSNotification } from '../../../api/notifications/domain/types';
import { NewUser } from '../components/renderers/NewUser';
import { Direct } from '../components/renderers/Direct';
import { AccessRequest } from '../components/renderers/AccessRequest';

export type DisplayableNotificationLink = {
  link: string;
  isClickable: boolean;
};

export type DisplayableNotification = {
  link: DisplayableNotificationLink;
  renderer?: () => React.ReactNode;
};

export type NotificationMatcher = {
  regex: RegExp;
  extractor: (data: PacTSNotification) => DisplayableNotification;
};

const projectLink = (projectId: string): DisplayableNotificationLink => {
  return { link: `/projects?active=${projectId}`, isClickable: true };
};
const projectAppsLink = (projectId: string, appId: string): DisplayableNotificationLink => {
  // TODO: Get rid of redirection route
  return { link: `/project-software-redirect?projectId=${projectId}&appId=${appId}&bundleName=apps&dialog=details`, isClickable: true };
};
const projectAppsVersionsLink = (projectId: string, appId: string): DisplayableNotificationLink => {
  // TODO: Get rid of redirection route
  return { link: `/project-software-redirect?projectId=${projectId}&appId=${appId}&bundleName=apps&dialog=versions`, isClickable: true };
};
const projectBundleConfigLink = (projectId: string, bundleName: string, configId: string): DisplayableNotificationLink => {
  return { link: `/projects/${projectId}/apps?r_config=${configId}`, isClickable: true };
};
const projectBundleConfigReleaseLink = (projectId: string, bundleName: string, configId: string, releaseId: string): DisplayableNotificationLink => {
  if (bundleName.toLocaleLowerCase().includes('app')) {
    return { link: `/projects/${projectId}/apps/configurations/${configId}?r_version=${releaseId}`, isClickable: true };
  }
  return { link: `/projects/${projectId}/tools/configurations/${configId}?r_version=${releaseId}`, isClickable: true };
};
const legacyDeploymentPlanLink = (projectId: string): DisplayableNotificationLink => {
  return { link: `/projects/${projectId}/deployments/environments`, isClickable: true };
};
const deploymentPlanLink = (projectId: string, environmentId: string): DisplayableNotificationLink => {
  return { link: `/projects/${projectId}/deployments/${environmentId}`, isClickable: true };
};
const toolsLink = (): DisplayableNotificationLink => {
  return { link: '/tools', isClickable: true };
};
const toolLink = (toolId: string): DisplayableNotificationLink => {
  return { link: `/tools?open_tool_details=${toolId}`, isClickable: true };
};
const toolVersionsLink = (toolId: string): DisplayableNotificationLink => {
  return { link: `/tools?open_tool_versions=${toolId}`, isClickable: true };
};
const appsLink = (): DisplayableNotificationLink => {
  return { link: '/apps', isClickable: true };
};
const appLink = (toolId: string): DisplayableNotificationLink => {
  return { link: `/apps?open_app_details=${toolId}&scope=common`, isClickable: true };
};
const appVersionsLink = (toolId: string): DisplayableNotificationLink => {
  return { link: `/apps?open_app_versions=${toolId}&scope=common`, isClickable: true };
};
const homeLinkWithNotificationsOpen = (): DisplayableNotificationLink => {
  return { link: '/?showNotifications=true', isClickable: false };
};
const userAdministrationLink = (notification?: PacTSNotification): DisplayableNotificationLink => {
  const search = notification?.content?.userGid ? `?gid=${notification.content.userGid}` : '';
  return { link: `/user-administration${search}`, isClickable: true };
};
const projectMemberAdministrationLink = (notification?: PacTSNotification): DisplayableNotificationLink => {
  const filters: string[] = [];
  if (notification?.content?.userName) filters.push(`name=${notification?.content?.userName}`);
  if (notification?.content?.projectName) filters.push(`project=${notification?.content?.projectName}`);
  return { link: `/project-member-administration?${filters.join('&')}`, isClickable: true };
};

export const notificationMatchers: NotificationMatcher[] = [
  { regex: /^projects\/\d+\/created$/, extractor: (d) => ({ title: `Project ${d.content.projectName} created`, link: projectLink(d.content.projectId) }) },
  { regex: /^projects\/\d+\/updated$/, extractor: (d) => ({ title: `Project ${d.content.projectName} updated`, link: projectLink(d.content.projectId) }) },
  { regex: /^projects\/\d+\/deleted$/, extractor: (d) => ({ title: `Project ${d.content.projectName} deleted`, link: projectLink(d.content.projectId) }) },
  {
    regex: /^projects\/\d+\/apps\/\d+\/created$/,
    extractor: (d) => ({
      link: projectAppsLink(d.content.projectId, d.content.appId)
    })
  },
  {
    regex: /^projects\/\d+\/apps\/\d+\/updated$/,
    extractor: (d) => ({
      link: projectAppsLink(d.content.projectId, d.content.appId)
    })
  },
  {
    regex: /^projects\/\d+\/apps\/\d+\/deleted$/,
    extractor: (d) => ({
      link: projectLink(d.content.projectId)
    })
  },
  {
    regex: /^projects\/\d+\/bundles\/\d+\/configurations\/\d+\/created$/,
    extractor: (d) => ({
      link: projectBundleConfigLink(d.content.projectId, d.content.bundleName, d.content.configId)
    })
  },
  {
    regex: /^projects\/\d+\/bundles\/\d+\/configurations\/\d+\/updated$/,
    extractor: (d) => ({
      link: projectBundleConfigLink(d.content.projectId, d.content.bundleName, d.content.configId)
    })
  },
  {
    regex: /^projects\/\d+\/bundles\/\d+\/configurations\/\d+\/deleted$/,
    extractor: (d) => ({
      link: projectLink(d.content.projectId)
    })
  },
  {
    regex: /^projects\/\d+\/bundles\/\d+\/configurations\/\d+\/releases\/\d+\/created$/,
    extractor: (d) => ({
      link: projectBundleConfigReleaseLink(d.content.projectId, d.content.bundleName, d.content.configId, d.content.releaseId)
    })
  },
  {
    regex: /^projects\/\d+\/bundles\/\d+\/configurations\/\d+\/releases\/\d+\/updated$/,
    extractor: (d) => ({
      link: projectBundleConfigReleaseLink(d.content.projectId, d.content.bundleName, d.content.configId, d.content.releaseId)
    })
  },
  {
    regex: /^projects\/\d+\/bundles\/\d+\/configurations\/\d+\/releases\/\d+\/deleted$/,
    extractor: (d) => ({
      link: projectLink(d.content.projectId)
    })
  },
  // TODO: deprecated, remove after migration
  {
    regex: /^projects\/\d+\/bundles\/\d+\/configurations\/\d+\/releases\/\d+\/deploymentplans\/[a-z0-9-]+\/created$/,
    extractor: (d) => ({
      link: legacyDeploymentPlanLink(d.content.projectId)
    })
  },
  // TODO: deprecated, remove after migration
  {
    regex: /projects\/\d+\/bundles\/\d+\/configurations\/\d+\/releases\/\d+\/deploymentplans\/[a-z0-9-]+\/locked$/,
    extractor: (d) => ({
      link: legacyDeploymentPlanLink(d.content.projectId)
    })
  },
  {
    regex: /projects\/\d+\/environments\/[a-z0-9-]+\/deploymentplans\/[a-z0-9-]+\/created$/,
    extractor: (d) => ({
      link: deploymentPlanLink(d.content.projectId, d.content.environmentId)
    })
  },
  {
    regex: /projects\/\d+\/environments\/[a-z0-9-]+\/deploymentplans\/[a-z0-9-]+\/locked$/,
    extractor: (d) => ({
      link: deploymentPlanLink(d.content.projectId, d.content.environmentId)
    })
  },
  {
    regex: /^projects\/\d+\/apps\/\d+\/versions\/\d+\/created$/,
    extractor: (d) => ({
      link: projectAppsVersionsLink(d.content.projectId, d.content.appId)
    })
  },
  {
    regex: /^projects\/\d+\/apps\/\d+\/versions\/\d+\/updated$/,
    extractor: (d) => ({
      link: projectAppsVersionsLink(d.content.projectId, d.content.appId)
    })
  },
  {
    regex: /^projects\/\d+\/apps\/\d+\/versions\/\d+\/deleted$/,
    extractor: (d) => ({
      link: projectAppsVersionsLink(d.content.projectId, d.content.appId)
    })
  },
  { regex: /^tools\/\d+\/created$/, extractor: (d) => ({ link: toolLink(d.content.toolId) }) },
  { regex: /^tools\/\d+\/updated$/, extractor: (d) => ({ link: toolLink(d.content.toolId) }) },
  { regex: /^tools\/\d+\/deleted$/, extractor: () => ({ link: toolsLink() }) },
  {
    regex: /tools\/\d+\/versions\/\d+\/created$/,
    extractor: (d) => ({ link: toolVersionsLink(d.content.toolId) })
  },
  {
    regex: /^tools\/\d+\/versions\/\d+\/updated$/,
    extractor: (d) => ({ link: toolVersionsLink(d.content.toolId) })
  },
  {
    regex: /^tools\/\d+\/versions\/\d+\/deleted$/,
    extractor: (d) => ({ link: toolVersionsLink(d.content.toolId) })
  },
  { regex: /^apps\/\d+\/created$/, extractor: (d) => ({ link: appLink(d.content.appId) }) },
  { regex: /^apps\/\d+\/updated$/, extractor: (d) => ({ link: appLink(d.content.appId) }) },
  { regex: /^apps\/\d+\/deleted$/, extractor: () => ({ link: appsLink() }) },
  {
    regex: /^apps\/\d+\/versions\/\d+\/created$/,
    extractor: (d) => ({ link: appVersionsLink(d.content.appId) })
  },
  {
    regex: /^apps\/\d+\/versions\/\d+\/updated$/,
    extractor: (d) => ({ link: appVersionsLink(d.content.appId) })
  },
  {
    regex: /^apps\/\d+\/versions\/\d+\/deleted$/,
    extractor: (d) => ({ link: appVersionsLink(d.content.appId) })
  },
  { regex: /^training\/announcement$/, extractor: (d) => ({ link: homeLinkWithNotificationsOpen(), renderer: () => SystemTraining({ notification: d }) }) },
  { regex: /^system\/announcement$/, extractor: (d) => ({ link: homeLinkWithNotificationsOpen(), renderer: () => SystemAnnouncement({ notification: d }) }) },
  { regex: /^system\/broadcast$/, extractor: (d) => ({ link: homeLinkWithNotificationsOpen(), renderer: () => SystemAnnouncement({ notification: d }) }) },
  { regex: /^system\/maintenance$/, extractor: (d) => ({ link: homeLinkWithNotificationsOpen(), renderer: () => SystemWarning({ notification: d }) }) },
  { regex: /^users\/\d+\/created$/, extractor: (d) => ({ link: userAdministrationLink(d), renderer: () => NewUser({ notification: d }) }) },
  { regex: /^users\/\d+\/removed$/, extractor: (d) => ({ link: userAdministrationLink(d) }) },
  { regex: /^users\/\d+\/roles\/updated$/, extractor: (d) => ({ link: userAdministrationLink(d) }) },
  { regex: /^projects\/\d+\/members\/\d+\/updated$/, extractor: (d) => ({ link: projectMemberAdministrationLink(d) }) },
  { regex: /^users\/\d+\/direct$/, extractor: (d) => ({ link: homeLinkWithNotificationsOpen(), renderer: () => Direct({ notification: d }) }) },
  {
    regex: /^projects\/\d+\/members\/\d+\/accessrequest\/created$/,
    extractor: (d) => ({
      link: projectLink(d.content.projectId),
      renderer: () => AccessRequest({ notification: d })
    })
  }
];
