import { InfoCircleOutlined, OrderedListOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { SoftwareAppSelection } from '../../../domain';
import { ExpandableMenu } from '../../shared/components/ExpandableMenu';
import type { ScopedSoftwareApp } from '../hooks/scopedSoftwareApp';
import { SoftwareAppsOpenInToolManagerButton } from './SoftwareAppsOpenInToolManagerButton';
import { SubscriptionPopup } from '../../notifications/components/SubscriptionPopup';
import type { Project } from '../../../api/engineering/domain/types';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { MetricClickIds } from '../../../contexts/metrics/constants';
import { DownloadContextMenuButton } from '../../shared/components/Download/components/DownloadContextMenuButton';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

interface ProjectSoftwareAppsActionMenuProps {
  swa: ScopedSoftwareApp;
  project: Project;
  currentSelection: SoftwareAppSelection | undefined;
  onOpenVersionDrawer: (appId: string) => void;
  onOpenDetailsDrawer: (appId: string) => void;
}

export const ProjectSoftwareAppsActionMenu: React.FC<ProjectSoftwareAppsActionMenuProps> = (props) => {
  const metrics = useSubmitMetric();
  const permissions = usePermissions({
    projectId: props.project.idProject.toString(),
    softwareAppId: props.currentSelection?.app.idSoftwareApp.toString(),
    idSoftwareAppVersion: props.currentSelection?.version.idSoftwareAppVersion.toString()
  });

  const canGetVersions =
    permissions.engineeringSvc$getProjectSoftwareAppVersions || permissions.engineeringSvc$getProjectSoftwareAppVersion$specific().length > 0;

  const handleOpenVersionDrawer = () => {
    props.onOpenVersionDrawer(props.swa.idSoftwareApp.toString());
  };

  const handleOpenDetailsDrawer = () => {
    props.onOpenDetailsDrawer(props.swa.idSoftwareApp.toString());
  };

  return (
    <>
      <Space>
        <SubscriptionPopup
          type="text"
          title={props.swa.name}
          topics={[{ key: `projects/${props.project.idProject}/apps/${props.swa.idSoftwareApp}/**`, title: props.swa.name }]}
        />
        <ExpandableMenu textType id={`project-app-actions-${props.project.idProject}-${props.swa.idSoftwareApp}`}>
          {permissions.webui$showComponentDetails && (
            <ExpandableMenuItem
              icon={<InfoCircleOutlined />}
              onClick={() => {
                metrics
                  .submitClick({
                    operationId: MetricClickIds.componentDetails
                  })
                  .withAppContext(props.swa, props.project);

                handleOpenDetailsDrawer();
              }}
            >
              Details
            </ExpandableMenuItem>
          )}
          {canGetVersions && (
            <ExpandableMenuItem
              icon={<OrderedListOutlined />}
              onClick={() => {
                metrics
                  .submitClick({
                    operationId: MetricClickIds.componentVersions
                  })
                  .withAppContext(props.swa, props.project);

                handleOpenVersionDrawer();
              }}
            >
              Versions
            </ExpandableMenuItem>
          )}
          {props.currentSelection && (
            <DownloadContextMenuButton artifact={props.currentSelection.app} version={props.currentSelection.version} projectId={props.project.idProject} />
          )}
          {props.currentSelection && <SoftwareAppsOpenInToolManagerButton selection={props.currentSelection} project={props.project} />}
        </ExpandableMenu>
      </Space>
    </>
  );
};
